.hero.media {
    margin: 0;
    height: auto;
    min-height: 350px;
    &:before {
        display: none;
    }
    h1 {
        color: $abp_purple;
        margin: 0 auto;
        max-width: 1000px;
    }
    h2 {
        margin-top: 15px;
        font-weight: bold;
    }
}

.byline.media {
    margin: 0;
    p {
        line-height: 130%;
    }
}

main.media {
    margin: 0;
    padding: 70px 0 45px;
    h2 {
        margin-bottom: 25px;
        font-weight: bold;
        color: $abp_purple;
        @include font-size(24);
    }
    .media-image {
        position: relative;
        height: 250px;
        background-size: cover;
        background-position: center center;
        a {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
    strong {
        display: block;
        margin: 10px 0;
        color: $orange;
    }
    h3 {
        margin: 0;
        font-weight: bold;
        @include font-size(18);
    }
    a {
        display: inline-block;
        font-weight: bold;
        text-decoration: none;
        color: $grey;
        &.read-more {
            margin-bottom: 25px;
        }
    }
    .media-hero {
        margin-bottom: 25px;
    }
    .media-related-links {
        li {
            margin-bottom: 10px;
            a {
                font-weight: normal;
            }
        }
    }
    hr {
        margin: 50px 0 25px;
        border-top: 2px solid $grey;
    }
}
// ABP Colours
$abp_blue:   #092C74;
$abp_red:    #F42434;
$abp_purple: #B11CAB;

// Site colours
$purple:      #665CC7;
$light_blue:  #06A7E2;
$dark_blue:   #345099;
$alt_blue:    #33B9E7;
$grey:        #466675;
$mid_grey:    #6A8191;
$light_grey:  #E3E3E3;
$green:       #00AD68;
$light_green: #80BD01;
$orange:      #FF6B00;

$mimi_alt:#F8D000;
$mimi_main:   #1D3664;
$mimi_sub:    #3E547B;

// Panel backgrounds
$pastel_blue:   #ECF5F8;
$pastel_orange: #FDF0E2;
$pastel_green:  #F2FBF7;
$pastel_grey:   #FAFAFA;
$dark_grey:     #333333;

// Widths
$max_width: 1280px;

// Fonts
$default_font_colour: $grey;
$default_body_font: "nimbus-sans", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial;
$default_heading_font: "nimbus-sans", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial;
$default_font_size: 14;

// Browser vendor prefixes
$vendors: -webkit-, -moz-, -ms-, -o-;

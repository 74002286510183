.half-panel {
    height: 35vw;
    min-height: 380px;
    max-height: 420px;
    margin-bottom: 50px;
    padding: 50px;
    &.need-space, &.get-in-touch, &.download-brochure {
        h2 {
            margin-bottom: .5em;
            @include font-size(36);   
        }
        p {
            margin-bottom: 2em;
            @include font-size(16);   
        }
    }
    &.need-space, &.get-in-touch {
        max-height: 320px;
        min-height: inherit;
        .button {
            margin-top: 25px !important;
        }
    }
    &.need-space, &.download-brochure {
        background: $abp_red;
        color: #FFF; 
        button, .button {
            display: inline-block;
            position: relative;
            margin-top: 50px;
            padding: 17px 25px 15px 45px;
            border: none;
            background: #FFF;
            color: $abp_red;   
            text-decoration: none;
            svg {
                @include absolute(12px, auto, auto, 20px);
                height: 25px;
                * {
                    stroke: $abp_red;   
                }
            }
        }
    }
    &.download-brochure-popout {
        background: $mimi_alt;
    }
    &.download-brochure {
        min-height: 335px;
        max-height: 375px;
        h2, p {
            padding-right: 50px;
            @include breakpoint(grid-sm) {
                padding-right: 0;    
            }
        }
        .button {
            margin: 10px 25px 15px 0;
            padding: 17px 25px 15px;
        }    
    }
    &.get-in-touch {
        background: $pastel_green;
        .headshot {
            max-width: 100px;   
            margin-bottom: 15px;
        }
        h2, h3 {
            color: $green;   
        }
        h3 {
            @include font-size(16);   
            font-weight: 400;
        }
        .ghost.button {
            border-color: $green;
            color: $green;
        }
    }
    &.orange {
        background: $pastel_orange;   
        h2, h3 {
            color: $orange;   
        }
    }
    @include breakpoint(alpha) {
        padding: 35px;   
    }
    @include breakpoint(grid-sm) {
        height: auto; 
        max-height: none;
    }
    @include breakpoint(grid-xs) {
        height: auto; 
        min-height: 240px;
        padding: 30px 35px;
        margin-bottom: 10px;
    }
    @include breakpoint(mobile) {
        min-height: 290px;
    }
}

.highlight-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 70px;
    background-size: cover !important;
    text-align: center;
    color: #FFF;
    h2, p, a {
        position: relative;
        z-index: 2;   
        width: 100%;
    }
    h2 {
        margin-bottom: .75em;
        @include font-size(36);
        font-weight: 700;   
    }
    p {
        display: none;
        margin-bottom: 2em;  
        line-height: 150%; 
        @include font-size(18);
    }
    a {
        display: inline-block;
        width: auto;
        height: auto;
        padding: 12px 50px;
        background-image: none;
        background-color: $orange;
        @include font-size(16);
        text-decoration: none;
        color: #FFF;  
        svg { 
            display: none; 
        }
        span { 
            display: block; 
        }
        &:hover {
            background: #FFF;
            color: $orange;  
        }
    }
    
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: ease-in-out .5s background;
    }
    
    &:hover {
        p { 
            display: block; 
        } 
        a {
            position: relative;
            width: auto;
            height: 25px;
            padding: 2px 0 0 35px;
            background: none !important;
            svg {
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                width: 25px;
                height: 25px;
                circle { 
                    fill: #FFFFFF; 
                }
                line, polygon { 
                    fill: $orange; 
                }
                line { 
                    stroke: $orange; 
                }
            }
            &:hover {
                color: #FFF !important;
            }
        }
    }
    
    &.available-property {
        border-right: 4px solid #FFF;
        background : url("/images/panel/available_property.jpg") center center no-repeat; 
        &:before {
            @extend %radial-background; 
        }
        &:hover {
            &:before {                  
                background: rgba($orange, .9);
            }   
        }
        @include breakpoint(grid-xs) {
            border-right: none;    
        }
    }
    &.development-opportunities {
        border-left: 4px solid #FFF;
        background : url("/images/panel/development_opportunities.jpg") center center no-repeat; 
        a {
            background: $green; 
            svg {
                line, polygon { 
                    fill: $green; 
                }
                line { 
                    stroke: $green; 
                }
            }
        }
        &:before {
            @extend %radial-background; 
        }
        &:hover {
            a:hover {
                background: #FFF;
                color: $green;   
            }
            &:before {                
                background: rgba($green, .9);
            }   
        }
        @include breakpoint(grid-xs) {
            border-left: none;    
        }
    }   
    @include breakpoint(grid-sm) {
        height: 50vw; 
        max-height: none;
    }
}

.panel {
    padding: 50px 0;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    &:last-of-type {
        margin-bottom: 50px;
        @include breakpoint(grid-xs) {
            margin-bottom: 0;    
        }
    }
    h2 {
        @include font-size(48);
        font-weight: bold;
    }
    h3 {
        margin: 25px 0 .5em 0;
        @include font-size(26);  
    }
    p {
        @include font-size(16);
        a {
            text-decoration: underline;   
        }
    }
    img {
        margin-top: 25px; 
        &:first-of-type {
            margin-top: 0; 
        }
    }
    & > .wrapper-padded > .grid { 
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
        @include breakpoint(grid-xs) {
            margin-bottom: 0;
        }
    }
    &.green {
        background: $pastel_green;
        h2, h3, a { 
            color: $green; 
        }   
    }
    &.dark-green {
        background: $green;
        h2, h3, p, a { 
            color: #FFF; 
        }   
    }
    &.green-pattern {
        background: url('/images/green_pattern.jpg');
        background-size: cover;
        background-position: top center;
        h2, h3, p, a { 
            color: #FFF; 
        } 
        h2 {
            margin-bottom: 50px;
            letter-spacing: -2px;
            @include font-size(92);
            @include breakpoint(grid-xs) {
                @include font-size(48);
                margin-bottom: 25px;
            }
        }
    }
    &.orange {
        background: $pastel_orange;   
        h2, h3, a {
            color: $orange;   
        }
    }
    &.blue {
        background: $pastel_blue;   
        h2, h3, a {
            color: $light_blue;   
        }
    }
    &.dark-blue {
        background: #34508D;   
        h2, h3, a {
            color: #CACACA;   
        }
        p {
            color: #FFF;
        }
        .svg-link svg {
            line {
                stroke: #34508D;
            }
            polygon {
                fill: #34508D;
            }
        }
    }
    &.blue-pattern {
        background: url('/images/blue_pattern.png');
        background-size: cover;
        h2, h3, p, a { 
            color: #FFF; 
        } 
        h2 {
            margin-bottom: 50px;
            letter-spacing: -2px;
            @include font-size(92);
            @include breakpoint(grid-xs) {
                @include font-size(48);    
            }
        }
    }
    &.grey {
        background: $pastel_grey;   
        h2, h3, a {
            color: $grey;   
        }
    }
    &.deep {
        padding: 70px 0;
        margin-bottom: 0 !important;
        @include breakpoint(grid-xs) {
            padding: 40px 0;    
        }
    }
    &.small-bottom-margin {
        padding-bottom: 25px;
        h2:last-child {
            margin-bottom: 0;
        }
    }
    &.overhang {
        padding-bottom: 150px;
        & + .panel .pull-up {
            margin-top: -180px !important;
            margin-bottom: 70px;
        }
        @include breakpoint(grid-xs) {
            padding-bottom: 0;  
            & + .panel .pull-up {
                margin-top: 0 !important;
                margin-bottom: 50px;
            }
        }
    }
    .cog-feature {
        margin-bottom: 1em;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.center-auto {
    display: block;
    margin: 0 auto;
    max-width: 1800px;
}
.hero.case-studies {
    border-bottom: none;
    background-image: url('/uploads/media/siemens.jpg');
}

.main-case-study {
    margin-bottom: 50px;
    h2 {
        @include font-size(46);
        font-weight: 700;
        color: $light_green;   
        @include breakpoint(grid-sm) {
            @include font-size(38);
        }
    }
    h3 {
        margin-bottom: 2em;
        @include font-size(24);
        font-weight: 300;
        @include breakpoint(grid-sm) {
            margin-bottom: 25px;
        }
    }
    h4 {
        margin: 1.5em 0 .5em;
        &:first-of-type {
            margin-top: 0;   
        }
    }
    @include breakpoint(grid-xs) {
        margin-bottom: 25px;   
    }
}

.case-studies-list {
    margin-bottom: 25px;
    .case-study {
        height: 25vw;
        margin-bottom: 30px;
        background-size: cover;
        div {
            width: 100%;
            @include font-size(28);
            text-align: center;
            color: #FFF;   
        }
        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .25);
            transition: ease-in .25s background;
            &:hover {
                background: rgba($dark_blue, .9); 
                text-decoration: none;
            }
        }
    }
    @include breakpoint(grid-xs) {
        margin-bottom: 0;   
        .case-study {
            height: 45vw;
        }
    }
}

.key-point.large {
    .value {
        float: left;
        span {
            @include font-size(96);
        }
        &.number {
            @include font-size(160);   
        }
        @include breakpoint(grid-sm) {
            margin-top: 50px;
            float: none;
            span {
                @include font-size(60);
            }
            &.number {
                @include font-size(90);   
            }      
        }
    }
    .key {
        float: left;
        margin: 85px 0 0 2em;
        max-width: 300px;
        @include breakpoint(grid-sm) {
            float: none;
            margin: 5px auto 0;
            max-width: none;
        }
    }
}

iframe {
    width: 100%;
    height: 49vw;
    max-height: 650px;
    margin-bottom: 25px;
}

@include breakpoint(grid-xs) {
    .main-photo {
        margin-top: 25px;   
    }
}

.other-photos img {
    margin-top: 30px;   
}

.quote {
    padding: 50px 0;
    .quote-citation {
        padding-left: 50px;
        img {
            max-width: 120px;   
        }
        .titles {
            margin-top: 25px;
            strong {
                display: block;
                color: $light_green;
            }   
        }
        @include breakpoint(grid-xs) {
            padding: 0 0 25px 0;
            text-align: center;   
        }
    }
    .quote-text {
        position: relative;
        padding: 0;
        margin: 0;
        border-left: 0;
        @include font-size(28);   
        p {
            strong {
                color: $light_green;
            }
        }
        &:before, &:after {
            position: absolute;
            @include font-size(96);
            color: $light_green;   
        }
        &:before {
            content: "\201C";
            left: -45px;
            top: -35px;
        }
        &:after {
            right: -10px;
            bottom: -70px;
            content: "\201D";
        } 
        @include breakpoint(grid-xs) {
            margin: 0 auto;
            width: 80%;
            @include font-size(20);
        }   
    }
}

.more-case-studies {
    margin: 50px 0;   
}

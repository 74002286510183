%center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

%angled-edge {
    content: " ";
    display: block;   
    position: absolute;
    top: 0;
    left: -30px;
    z-index: -1;
    transform: rotate(25deg);   
}

%pseudo-right-slant {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(45deg);
    -ms-transform: skew(45deg);
    transform: skew(45deg);
    z-index: -1;
    background: $mimi_alt;
}

%radial-background {
    background: none;
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,.5) 0%, transparent 60%); 
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,.5) 0%,transparent 60%); 
    background: radial-gradient(ellipse at center, rgba(0,0,0,.5) 0%,transparent 60%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0,0,0,.5)', endColorstr='rgba(0,0,0,0)',GradientType=1 );
}

%legend {
    @include absolute(30px, auto, auto, 30px);
    overflow: hidden;
    width: 380px;
    padding: 25px 90px 25px 30px;
    h2, h3, h4, p, a {
        position: relative;
        z-index: 1;
        color: #FFF;   
    }
    h2 {
        margin-bottom: .5em;   
        @include font-size(36);
    }
    p { 
        margin-bottom: 1em;
        line-height: 130%;
    }
    &:after {
        @extend %angled-edge;   
        top: -155px;
        left: auto;
        right: 70px;
        z-index: 0;
        width: 500px;
        height: 500px;
        background: rgba($light_blue, .9);
    } 
    @include breakpoint(grid-sm) {
        width: 450px;    
    }
}

%close-button {
    @include absolute(10px, 12px);
    z-index: 20;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    span {
        @include absolute(13px, 0);
        width: 30px;
        height: 2px;
        background: $dark_blue;
        transform: rotate(45deg);  
        &:last-child {
            transform: rotate(-45deg);   
        }
    }
}
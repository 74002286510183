.hero.home {
    display: block;
    position: relative;
    overflow: hidden;
    @include breakpoint(grid-xs) {
        height: calc(100vh - 130px);
        min-height: inherit;
    }
    @media screen and (max-width: 600px) {
        height: auto;
        h1 {
            @include font-size(25);
        }
        .quick-search {
            margin-top: 1em;
        }
    }    
    
}

#home-carousel {
    height: 45vw;
    min-height: 537px;
    max-height: 850px;
    .item {
        height: 45vw;
        min-height: 575px;
        max-height: 850px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        &:first-child { 
            background-image: url("/images/hero/home2.jpg"); 
        }
        &:nth-child(2) { 
            background-image: url("/images/hero/home3.jpg"); 
        }
        &:nth-child(3) { 
            background-image: url("/images/hero/home4.jpg"); 
        }
    }
    .carousel-control {
        z-index: 500;
        @include breakpoint(grid-xs) {
            display: none;
        }
    }
    @include breakpoint(grid-xs) {
        &, .item {
            height: calc(100vh - 130px);
            max-height: none;
            min-height: inherit;
        }    
    }
}

#video {
    margin-bottom: 50px;
    height: 56vw;
    max-height: 715px;
}

.hero-content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);    
}
/* Navigation menu */
nav {
    height: 65px;
    ul {
        display: flex;
        justify-content: flex-start;
        margin-left: 2em;
        li {
            margin: 0 2em 0 0;
            &:last-of-type { margin-right: 0; }
        }
        a {
            position: relative;
            display: block;
            height: 65px; 
            padding: 28px 0 0;  
            @include font-size(14);
            text-decoration: none;
            line-height: 100%;
            color: $dark_blue;
            transition: .3s linear color;
            &:hover {
                color: $dark_grey; 
                &:after {
                     position: absolute;
                     bottom: 15px;
                     display: block;
                     content: "";
                     width: 100%;
                     border-bottom: 1px solid $dark_grey;  
                }
            }
        }
    }
    @include breakpoint(grid-sm) {
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 200;
        width: 300px;
        height: 100%;
        background: #EEE;
        ul {
            display: block;
            margin: 0;
            border-bottom: 1px solid #EEE;
            li {
                margin: 0;   
            }
            a {
                width: 100%;
                height: auto;
                padding: 18px 5px;  
                background: #F9F9F9; 
                border-top: 1px solid #EEE;
                @include font-size(18);
                text-align: center;
                &:hover {
                    background: $pastel_blue;   
                    &:after { display: none; }
                }
            }
        }
    }
}

/* Burger menu */
.burger {
    @include absolute(0, 0);
    display: none;
    text-indent: -9999px;
    @include prefix(( transition: background-color .25s linear ));
    &:before {
        content: "";
        position: absolute;
        z-index: 7;
        right: 32px;
        top: 22px;
        width: 30px;
        height: 4px;
        background: #FFF;
        box-shadow: 0 8px 0 0 #FFF, 0 16px 0 0 #FFF;   
    }
    @include breakpoint(grid-sm) { 
        display: block; 
        width: 79px;
        top: 32px;
        height: 65px;
        background: $dark_blue;
        &:after {
            content: " ";
            display: block;   
            position: absolute;
            top: 0;
            left: -30px;
            z-index: 0;
            width: 50px;
            height: 125px;
            background-color: $dark_blue; 
            transform: rotate(25deg);  
        }
    }
    
    @include breakpoint(mobile) { 
        top: 54px;
    }

}

/* Close menu icon */
.close {
    @extend %close-button;
    display: none;
    @include breakpoint(grid-sm) { 
        display: block; 
    } 
}
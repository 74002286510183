.priority-port-nav {
    position: fixed;
    top: 65px;
    left: 0;
    z-index: 100;
    width: 100%;
    background: #1F3664;
    ul {
        display: flex;
        justify-content: space-between;
        a {
            display: block;
            padding: 20px 40px;
            font-weight: bold;
            text-decoration: none;
            color: #FFF;
            &:hover {
                background: $green;
            }
        }
    }
    @include breakpoint(grid-xs) {
        display: none;    
    }
}

.priority-port-map {  
    padding-top: 61px;
    background: #1F3664;
    .center-auto {
        position: relative;
    }
    .priority-port-map--labels {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 1;
            transition: opacity .3s ease;
            &.hidden {
                opacity: 0;
            }
        }
        @media screen and (max-width: 1100px) {
            display: none;
        }
    }
    .priority-port-map--controls {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a {
            display: block;
            width: 70px;
            height: 70px;
            text-align: center;
            padding: 12px;
            background: $grey;
            transition: background .2s ease;
            &.active {
                &.rail {
                    background-color: $abp-red;
                    &:hover {
                        background-color: lighten($abp-red, 7%);
                    }
                }
                &.sea {
                    background-color: $light-blue;
                    &:hover {
                        background-color: lighten($light-blue, 7%);
                    }
                }
                &.road {
                    background-color: $orange;
                    &:hover {
                        background-color: lighten($orange, 7%);
                    }
                }
                &.info {
                    background-color: $dark-blue;
                    &:hover {
                        background-color: lighten($dark-blue, 7%);
                    }
                }
            }
        }
        @media screen and (max-width: 1100px) {
            display: none;
        }
    }
    @include breakpoint(grid-xs) {
        padding-top: 0;    
    }
}

.priority-port-intro {
    background: url('/images/blue_pattern.png');
    background-size: cover;
    padding: 70px 0;
    color: #CCC;
    h1 {
        margin-bottom: 0;
        font-weight: 700;
        color: #CCC !important;
        @include font-size(60);
        @include breakpoint(grid-xs) {
            line-height: 90%;    
        }
    }
    h2 {
        margin-bottom: 35px;
        color: #CCC !important;
        @include font-size(42);
        @include breakpoint(grid-xs) {
            margin-top: 15px;
            @include font-size(36);  
        }
    }
    p {
        max-width: 490px;
        margin-bottom: 25px;
        line-height: 110%;
        @include font-size(28);
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    @include breakpoint(grid-xs) {
        padding: 40px 0;  
        img {
            margin-top: 25px;
        }
    }
}

.priority-port-stats {    
    span {
        display: block;
        @include font-size(16);
    }
    strong {
        display: block;
        margin-bottom: 50px;
        line-height: 100%;
        letter-spacing: -1px;
        color: $green;
        @include font-size(46);
        span {
            display: inline;
            @include font-size(24);
        }
    }
    .col {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            position: relative;
            &:after {
                position: absolute;
                bottom: 23px;
                left: 2em;
                content: "";
                width: calc(100% - 4em);
                height: 1px;
                background: $grey;
            }
        }
        @include breakpoint(grid-sm) {
            &:nth-child(4) {
                position: relative;
                &:after {
                    position: absolute;
                    bottom: 23px;
                    left: 2em;
                    content: "";
                    width: calc(100% - 4em);
                    height: 1px;
                    background: $grey;
                }
            }
        }
        @include breakpoint(grid-xs) {
            &:nth-child(5) {
                position: relative;
                &:after {
                    position: absolute;
                    bottom: 23px;
                    left: 2em;
                    content: "";
                    width: calc(100% - 4em);
                    height: 1px;
                    background: $grey;
                }
            }
        }
    }
}

.priority-port-video {
    p {
        font-weight: 400;
        @include font-size(20);
    }
    @include breakpoint(grid-xs) {
        img {
            margin-bottom: 25px;
        }    
    }
}

.priority-port-opportunity {
    & > img {
        width: 100%;
        max-height: 800px;
        object-fit: cover;
    }
    section {
        position: relative;
        &.center-auto {
            max-width: 1800px;
        }
    }
    h2 {
        position: absolute;
        bottom: -39px;
        left: 0;
        z-index: 90;
        letter-spacing: -5px;
        font-weight: 700;
        color: #FFF;
        @include font-size(180);
        @include breakpoint(grid-sm) {
            bottom: -26px;
            @include font-size(120);
        }
        @include breakpoint(grid-xs) {
            bottom: -15px;
            @include font-size(72);
        }
    }
    .grid {
        margin: 0 -2em;
        .col {
            padding: 0 2em;
        }
    }
    p.large {
        max-width: 400px;
        line-height: 110%;
        font-weight: 700;
        @include font-size(32);
        @include breakpoint(grid-sm) {
            max-width: none;
            margin-top: 35px;
        }
        @include breakpoint(grid-xs) {
            @include font-size(26);    
        }
    }
    
}

.priority-port-bullets, .priority-port-transport, .priority-port-plan {
    h3 {
        margin: 0 0 20px;
        color: $green;
        @include font-size(22);
    }
}

.priority-port-bullets {
    ul {
        margin-bottom: 35px;
        &:last-of-type {
            margin-bottom: 0;
        }
        li {
            position: relative;
            padding-left: 25px;
            margin-bottom: 10px;
            line-height: 120%;
            @include font-size(16);
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "+";
                color: $green;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    @include breakpoint(grid-xs) {
        .col:nth-child(2) h3 {
            margin-top: 35px;
        }
    }
}

.panel.deep.green-pattern {
    p.intro {
        margin-bottom: 70px;
        font-weight: 700;
        line-height: 120%;
        @include font-size(28);
        @include breakpoint(grid-xs) {
            @include font-size(18); 
            margin-bottom: 40px;
        }
    }
    .plus-button {
        position: relative;
        display: inline-block;
        padding-left: 80px;
        line-height: 60px;
        height: 60px;
        font-weight: bold;
        text-decoration: none !important;        
        @include font-size(18);
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 60px;
            transition: transform .2s linear;
        }
        &.active {
            img {
                transform: rotate(45deg);
            }
        }
    }
    .priority-port-stats {
        span, strong {
            color: #FFF;
        }
        strong {
            margin: 0;
            @include font-size(78);
        }
        cite {
            display: block;
            color: #FFF;
        }
        p {
            margin-bottom: 5px;
            font-weight: bold;
            line-height: 110%;
        }
        .col { 
            & > *:last-child {
                margin-bottom: 50px;
            }
            &:nth-child(1), &:nth-child(2), &:nth-child(3) {            
                &:after {
                    background: #FFF;
                }
            }
            @include breakpoint(grid-sm) {
                &:nth-child(4) {            
                    &:after {
                        background: #FFF;
                    }
                }
            }
            @include breakpoint(grid-xs) {
                &:nth-child(5) {            
                    &:after {
                        background: #FFF;
                    }
                }
            }
        }
    }
}

.priority-port-existing {
    max-width: 800px;
    h2 {
        margin-bottom: 50px;
        letter-spacing: -2px;
        line-height: 90%;
        color: $green;
        @include font-size(92);
        @include breakpoint(grid-xs) {
            @include font-size(48);
            margin-bottom: 25px;
        }
    }
    .logos {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        .logo {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            flex-basis: 31%;
            min-height: 240px;
            padding: 25px;
            margin-top: 30px;
            border: 1px solid $grey;
            img {
                max-height: 100px;
            }
            @include breakpoint(grid-xs) {
                flex-basis: 100%;    
            }
        }
    }
}

.priority-port-transport-map {
    display: none;
    margin-top: 35px;
    padding: 25px;
    background: #FFF;
}

.priority-port-transport {
    margin: 0 -2em 50px !important;
    .col {
        padding: 0 2em;
    }
    table {
        width: 100%;
        max-width: 350px;
        margin-top: 35px;
        border: 1px solid $grey;
        tr {
            border-bottom: none;
        }
        th, td {
            padding: 10px 15px;
            @include font-size(14);
        }
        th {
            font-weight: 700;
        }
    } 
    @include breakpoint(grid-xs) {
        table {
            margin-bottom: 0;            
        }
        &:nth-of-type(odd) {
            .col:first-child {
                order: 1;
            }
            .col:last-child {
                order: 2;
            }
        }
        &:nth-of-type(even) {
            .col:first-child {
                order: 2;
            }
            .col:last-child {
                order: 1;
            }
        }
        img {
            margin-bottom: 25px;
        }
    }
}

.priority-port-master-plan {
    display: none;
    margin-top: 35px;
}

.priority-port-plan {
    table {
        border-top: 1px solid $grey;        
        td {
            width: 33.33%;
            padding: 10px 15px;
            &:last-child {
                text-align: right;
            }
            @include breakpoint(grid-xs) {
                padding: 10px 8px;
            }
        }
        tbody tr {
            &:nth-child(even) td {
                background: #F5F5F5;
            }
            &:hover td, &.active td {
                background: $green;
                color: #FFF;
                cursor: help;
            }
        }
        tfoot td {
            font-weight: bold;
        }
    }
    p.green {
        margin-bottom: 25px;
        font-weight: bold;
        color: $green;
    }
    hr {
        margin: 50px 0;
        border-top-color: $grey;
    }
    svg {
        polygon {
            cursor: help;
            &.active {
                fill: $green;
            }
        }
        @include breakpoint(grid-xs) {
            display: none;
        }
    }
    @include breakpoint(grid-xs) {
        .grid:nth-of-type(2) > .col:nth-child(2) h3 {
            margin-top: 17px;
        }
        h3 {
            margin-bottom: 10px;
        }    
    }
}

.priority-port-contact {
    p.intro {
        margin-bottom: 35px;
        line-height: 120%;
        font-weight: bold;
        @include font-size(26);
    }
    .priority-port-contact--company {
        margin-bottom: 35px;
        &:last-of-type {
            margin-bottom: 0;
        }
        img {
            width: 280px;
            margin-bottom: 25px;
        }
        h4 {
            margin-bottom: 10px;
            font-weight: bold;
            color: $green;
            @include font-size(18);
            @include breakpoint(grid-xs) {
                margin-top: 15px;    
            }
        }
        h5 {
            margin-bottom: 10px;
            font-weight: bold;
            @include font-size(16);
        }
        p {
            line-height: 200%;
            a {
                color: $grey;
                text-decoration: none;
            }
        }
    }
    h3 {
        margin: 0 0 10px;
        font-weight: bold;
        @include breakpoint(grid-xs) {
            margin-top: 25px;    
        }
    }
    form {
        margin-top: 35px;
        input, textarea {
            display: block;
            width: 100%;
            padding: 10px 15px;
            margin-bottom: 10px;
            border: 1px solid $grey;
            color: $grey;
            @include font-size(18);
            &::placeholder {
                color: #AAA;
            }
        }
        textarea {
            height: 120px;
            resize: none;
        }
        button {
            padding: 10px 45px;
            border: 1px solid $green;
            background: none;
            @include font-size(18);
        }
    }
}

.priority-port-brochure {
    border-bottom-color: $green;
    &:hover {
        border-bottom-color: lighten($green, 5%);
    }
    &.mobile-link {
        display: none;
    }
    @include breakpoint(grid-xs) {
        &.fixed-download-brochure-link {
            display: none;
        }
        &.mobile-link {
            display: block;
            margin-top: 35px;
            padding: 15px 15px;
            background: $green;
            text-align: center;
            color: #FFF;
            font-weight: bold;
            @include font-size(18);
        }
    }
}
.priority-port-brochure-content .half-panel.download-brochure {
    background: $green;
    .button {
        color: $green;
    }
}
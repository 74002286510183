.case-studies {
    clear: both;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    .case-studies-main {
        position: relative;
        height: 510px;
        overflow: hidden;
        background-size: cover;
        background-position: center center;   
        img {
            width: auto;
            min-height: 510px;
            @include breakpoint(grid-xs) {
                min-height: inherit; 
                float: left;     
            }
        }
        .meta {
            @include absolute(30px, 30px); 
            @include breakpoint(beta) {
                top: auto;
                right: auto;
                bottom: 90px;  
                left: 30px;
            }
            @include breakpoint(grid-xs) {
                bottom: 100px;   
            }
        }
        .legend {
            @extend %legend;
            &:after {
                background: rgba($dark_blue, .9);   
            }
            a svg {
                line, polygon {
                    fill: $dark_blue;   
                }
                line {
                    stroke: $dark_blue;   
                }
            }
            @include breakpoint(grid-xs) {
                position: static;  
                width: auto;
                height: auto;
                background: $dark_blue;
                padding: 25px 30px;
                &:after {
                    background: none;
                    display: none;   
                }
            }
        }
        .key-info {
            @include absolute(auto, auto, 30px, 30px);
            width: calc(100% - 60px);
            padding: 15px 30px;
            background: rgba($dark_blue, .9);
            font-weight: 400;
            @include font-size(16);
            color: #FFF;   
            span {
                font-weight: 300;
                @include font-size(14);   
            }
            @include breakpoint(grid-xs) {
                position: static;   
                clear: both;
                width: auto;
                
            }
        }
        @include breakpoint(beta) {
            height: 420px;
        }
        @include breakpoint(grid-xs) {
            height: auto;   
        }
    }
    .case-studies-list {
        position: relative;
        .scroller {
            height: 510px;
            overflow: hidden;   
            @include breakpoint(beta) {
                height: 420px;
            }
            @include breakpoint(grid-xs) {
                height: auto;
                overflow: visible;   
            }
        }
        li {
            height: 170px;
            margin: 0; 
            background-size: cover;
            background-position: center center;   
            text-align: center;
            a {
                display: flex;
                align-items: center;
                position: relative;
                width: 100%;
                height: 100%;
                text-decoration: none;
                font-weight: bold;
                color: #FFF;
                background: rgba(0, 0, 0, .25);
                transition: ease-in .25s background;
                svg {
                    display: none;
                }
                &:hover {
                    background: rgba($dark_blue, .9);
                }
            }
            span {
                display: block; 
                width: 100%;  
                @include font-size(16);
            }
            @include breakpoint(beta) {
                height: 140px;
            }
            @include breakpoint(grid-xs) {
                height: auto;
                background-image: none !important;   
                background: $light_blue;
                &:nth-child(odd) {
                    background: $alt_blue;   
                }
                a {
                    position: relative;
                    padding: 15px 30px;
                    background: transparent;
                    text-align: left;
                    font-weight: 400;
                    svg {
                        display: block;
                        position: absolute;
                        right: 30px;
                        top: 10px;
                        width: 25px;
                        circle { 
                            fill: #FFF;
                        } 
                        line, polygon {
                            stroke: $light_blue;
                        }
                        polygon {
                            fill: $light_blue;
                        }
                    }
                }
            }
        }
        .prev, .next {
            position: absolute;
            top: -15px;
            left: calc(50% - 16px);
            z-index: 45;
            display: block;
            width: 30px;
            height: 30px;  
            transform: rotate(-90deg); 
            svg {
                circle {
                    fill: #FFF;   
                }
                line, polygon {
                    fill: $dark_blue;   
                }
                line {
                    stroke: $dark_blue;   
                }      
            }
            &:hover {
                svg circle {
                    fill: #EEE;   
                }
            }
            @include breakpoint(grid-xs) {
                display: none;   
            }
        }
        .next {
            top: auto;
            bottom: -16px;
            transform: rotate(90deg); 
        }
    }
    @include breakpoint(grid-xs) {
        border-bottom: none;   
    }
}
/***************
*  HERO IMAGEs
***************/
.hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    height: 45vw;
    min-height: 575px;
    max-height: 850px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;  
    .carousel {
        position: relative;
        .carousel-indicators {
            z-index: 120;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            @extend %radial-background;   
            z-index: 100;
            @include breakpoint(grid-xs) {
                top: 50px;    
            }
        }
        
    }
    &.property .carousel:before {
        display: none;
    }
    .hero-content {
        z-index: 120;
        text-align: center;
        vertical-align: middle;  
        .scroll-down {
            position: absolute;
            bottom: -90px;
            left: 50%;
            z-index: 2;
            display: inline-block;
            transform: translate(0, -50%);
            transition: opacity .3s;
            &:hover {
                opacity: .5;
            }
            span {
                position: absolute;
                top: 0;
                left: 50%;
                width: 24px;
                height: 24px;
                margin-left: -12px;
                border-left: 2px solid #fff;
                border-bottom: 2px solid #fff;
                transform: rotate(-45deg);
                animation: scrollDown 2s infinite;
                box-sizing: border-box;
            }
        }
    }
    h1 {
        width: 100%;
        @include font-size(64);
        font-weight: bold;
        text-align: center;
        color: #FFF; 
        @include breakpoint(grid-xs) {
            @include font-size(32);
        }
    }
    .quick-search {
        width: 90%;
        margin: 2em auto 1em;
        fieldset {
            position: relative;
            display: block;
            overflow: hidden;
            width: 100%;
            max-width: 380px;
            padding: 0;
            margin: 0 auto;
            border: 1px solid $abp_red;
            background: #FFF;
            text-align: left;
            &:after {
                content: " ";
                display: block;   
                position: absolute;
                top: 9px;
                right: 95px;
                width: 22px;
                height: 22px;
                background: url("/images/icon/arrow_right.svg");
                background-size: 22px 22px;
                transform: rotate(90deg);
                pointer-events: none;                
            }
        }
        select {
            width: calc(100% - 90px);
            height: 40px;
            padding: 10px 15px;
            border: none;
            background: #FFF;
            @include font-size(16);            
            cursor: pointer;
            outline: none;
            -moz-appearance: none;  
            -webkit-appearance: none;   
            @include breakpoint(grid-xs) {
                @include font-size(14);
            }
        }
        button {
            position: relative;
            z-index: 1;
            float: right;
            height: 40px;
            padding: 10px 15px 10px 5px; 
            border: none;
            background: $abp_red;
            @include font-size(14);
            font-weight: bold;               
            color: #FFF;
            outline: none;
            &:before {
                @extend %angled-edge;
                width: 50px;
                height: 125px;
                background-color: $abp_red;
            }
        }
    }
    .advanced-search {
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        text-decoration: none;
        @include font-size(14);
        color: #FFF;   
    }
    &.supplementary {
        height: 200px;
        min-height: 200px;
    }
    @include breakpoint(grid-sm) {
        height: 400px;   
    }
    @include breakpoint(grid-xs) {
        height: 300px;   
    }
}

@keyframes scrollDown {
    0% {
        transform: rotate(-45deg) translate(0, 0);
    }
    20% {
        transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
        transform: rotate(-45deg) translate(0, 0);
    }
}
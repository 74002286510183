.grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1em;
    .col {
        flex: 1; 
        padding: 0 1em;
        
        &[class*="col-"] { 
            flex: none; 
            @include breakpoint(grid-xs) {
                flex: 0 0 100% !important;  
                width: 100%;
            }
        }
        
        &.col-12 { flex: 0 0 100%; }
        &.col-11 { flex: 0 0 91.6666666%; }
        &.col-10 { flex: 0 0 83.3333333%; }
        &.col-9 {  flex: 0 0 75%; }
        &.col-8 {  flex: 0 0 66.6666666%; }
        &.col-7 {  flex: 0 0 58.3333333%; }
        &.col-6 {  flex: 0 0 50%; }
        &.col-5 {  flex: 0 0 41.6666666%; }
        &.col-4 {  flex: 0 0 33.3333333%; }
        &.col-3 {  flex: 0 0 25%; }
        &.col-2 {  flex: 0 0 16.6666666%; }
        &.col-1 {  flex: 0 0 8.3333333%; }         
        
        @include breakpoint(grid-sm) {
            &.col-sm-12 { flex: 0 0 100%; }
            &.col-sm-11 { flex: 0 0 91.6666666%; }
            &.col-sm-10 { flex: 0 0 83.3333333%; }
            &.col-sm-9 {  flex: 0 0 75%; }
            &.col-sm-8 {  flex: 0 0 66.6666666%; }
            &.col-sm-7 {  flex: 0 0 58.3333333%; }
            &.col-sm-6 {  flex: 0 0 50%; }
            &.col-sm-5 {  flex: 0 0 41.6666666%; }
            &.col-sm-4 {  flex: 0 0 33.3333333%; }
            &.col-sm-3 {  flex: 0 0 25%; }
            &.col-sm-2 {  flex: 0 0 16.6666666%; }
            &.col-sm-1 {  flex: 0 0 8.3333333%; }   
        }
        
        @include breakpoint(grid-xs) {
            &.col-xs-12 { flex: 0 0 100% !important; }
            &.col-xs-11 { flex: 0 0 91.6666666% !important; }
            &.col-xs-10 { flex: 0 0 83.3333333% !important; }
            &.col-xs-9 {  flex: 0 0 75% !important; }
            &.col-xs-8 {  flex: 0 0 66.6666666% !important; }
            &.col-xs-7 {  flex: 0 0 58.3333333% !important; }
            &.col-xs-6 {  flex: 0 0 50% !important; }
            &.col-xs-5 {  flex: 0 0 41.6666666% !important; }
            &.col-xs-4 {  flex: 0 0 33.3333333% !important; }
            &.col-xs-3 {  flex: 0 0 25% !important; }
            &.col-xs-2 {  flex: 0 0 16.6666666% !important; }
            &.col-xs-1 {  flex: 0 0 8.3333333% !important; }   
        }
        
    }
    &.no-padding {
        margin: 0;
        & > .col {
            padding: 0;   
        }
    }
    &.wide {
        margin: 0 -2em;
        & > .col {
            padding: 0 2em;
        }
    }
}
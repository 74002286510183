.hero.contact-us {
    height: 200px;
    min-height: 200px;
    &:before {
        display: none;
    }
    h1 {
        color: $abp_red;
    }
}

.contacts {
    padding-bottom: 50px;
    h2 {
        @include font-size(48);
        margin-bottom: 25px;
        font-weight: bold;
        color: $abp_blue;
    }
    .col {
        padding-top: 50px;
        h3 {
            margin-bottom: 10px;
            color: $abp_blue;
            @include font-size(28);
        }
        h4 {
            margin-bottom: 15px;
            font-weight: bold;
            @include font-size(16);
        }
        p.ports {
            color: $abp_blue;
            font-style: italic;
        }
    }
}
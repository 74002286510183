.interactive-map {
    position: relative;
    height: 748px;    
    img {
        float: left;   
    }
    .legend {
        @extend %legend;
        a svg {
            line, polygon { 
                fill: $light_blue; 
            }
            line { 
                stroke: $light_blue; 
            }
        }
        @include breakpoint(grid-xs) {
            position: static;  
            width: auto;
            height: auto;
            background: $dark_blue;
            padding: 25px 30px;
            a svg {
                line, polygon { 
                    fill: $dark_blue; 
                }
                line { 
                    stroke: $dark_blue; 
                }
            }
            &:after {
                background: none;
                display: none;   
            }
        }
    }
    .our-ports {
        display: none;   
    }
    .marker {
        position: absolute;
        padding: 5px 10px;
        background: rgba($light_blue, .9);
        border: 1px solid #FFF;
        text-align: center; 
        text-decoration: none;  
        font-weight: 300;
        color: #FFF; 
        @include font-size(16);
        &.tl, &.tr, &.br, &.bl {
            &:after {
                content: "";
                display: block;
                position: absolute;
                z-index: 5; 
                width: 6px;
                height: 6px;
                background: #FFF;
                border-radius: 50%;
            }
        }
        &.tl:after { 
            top: -4px; 
            left: -4px;
        }
        &.tr:after {
            top: -4px; 
            right: -4px;
        }
        &.br:after { 
            bottom: -4px; 
            right: -4px;
        }
        &.bl:after {
            bottom: -4px; 
            left: -4px;
        }
        &[data-port="ayr"]         { top: 19%; left: 48.5%; }
        &[data-port="barrow"]      { top: 31%; left: 50%; }
        &[data-port="barry"]       { top: 63%; left: 38.5%; }
        &[data-port="cardiff"]     { top: 62%; left: 45%; }
        &[data-port="fleetwood"]   { top: 31.25%; left: 56.5%; }
        &[data-port="garston"]     { top: 41%; left: 48%; }
        &[data-port="goole"]       { top: 38.5%; left: 66.5%; }
        &[data-port="grimsby"]     { top: 47%; left: 76%; }
        &[data-port="hull"]        { top: 39%; left: 74%; }
        &[data-port="immingham"]   { top: 45%; left: 66.5%; }
        &[data-port="ipswich"]     { top: 70.5%; left: 86%; }
        &[data-port="kings-lynn"]  { top: 58%; left: 69%; }
        &[data-port="lowestoft"]   { top: 63%; left: 90.3%; }
        &[data-port="newport"]     { top: 57%; left: 46%; }
        &[data-port="port-talbot"] { top: 54%; left: 34.5%; }
        &[data-port="plymouth"]    { top: 78%; left: 14%; }
        &[data-port="silloth"]     { top: 22%; left: 59%; }
        &[data-port="southampton"] { top: 82%; left: 42.5%; }
        &[data-port="swansea"]     { top: 57.5%; left: 27%; }
        &[data-port="teignmouth"]  { top: 75%; left: 27.5%; }
        &[data-port="troon"]       { top: 14.5%; left: 52.5%; }
        &:hover {
            background: rgba($purple, .9);
        }
    }
    @include breakpoint(map) {
        height: auto;
        .marker[data-port] { display: none; }
    }
    @include breakpoint(grid-xs) {
        margin-bottom: 10px;
        .our-ports {
            display: block;
            clear: both;
            a {
                display: block;
                position: relative;
                padding: 15px 30px;
                background: $light_blue;
                @include font-size(18);
                font-weight: bold;
                text-decoration: none;
                color: #FFF;
                span {
                    @include absolute(23px, 30px);
                    display: block;
                    width: 18px;
                    height: 4px;
                    background: #FFF;
                    &:after {
                        content: " ";
                        position: absolute;
                        top: -7px;
                        left: 7px;
                        display: block;
                        width: 4px;
                        height: 18px;
                        background: #FFF;
                    }
                }
                &.open {
                    background: $dark_blue;   
                    span:after { display: none; }
                }
            }
        }
        .markers {
            display: none;
            clear: both;
            .marker[data-port] {
                display: block;
                position: static;
                padding: 15px 30px;
                border: none;
                text-align: left;
                font-weight: 400;
                &:nth-child(odd) {
                    background: $alt_blue;   
                }
                &.tl, &.tr, &.br, &.bl {
                    &:after {
                        display: none;
                    }
                }
            }
            
        }
    }
}

.infobox {
    display: none;
    position: absolute;
    z-index: 50;
    width: 280px;
    min-height: 150px;
    padding: 20px;
    background: $light_blue;
    border: 1px solid #FFF;
    color: #FFF;
    h3 {
        margin-bottom: .5em;
        @include font-size(20);   
    }
    p {
        margin-bottom: .25em;   
    }
    .more-info {
        display: inline-block;
        position: relative;
        height: 25px;
        padding: 5px 0 0 30px;
        margin-top: 1em;
        @include font-size(12);   
        text-decoration: none;
        color: #FFF;
        svg { 
            @include absolute(0, auto, auto, 0);
            width: 25px;
            height: 25px;
            circle { 
                fill: #FFF; 
            }
            line, polygon { 
                fill: $light_blue; 
            }
            line { 
                stroke: $light_blue; 
            }
        }
    }
    .close {
        @extend %close-button;
        span {
            background: #FFF;   
        }
    }
    &:after, &:before {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        height: 0;
        width: 0;
        border: solid transparent;
        pointer-events: none;
    }
    &:after {
        margin-left: -17px;
        border-color: rgba(6, 167, 226, 0);
        border-bottom-color: #06A7E2;
        border-width: 17px;
    }
    &:before {
        margin-left: -18px;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #FFF;
        border-width: 18px;
    }
}
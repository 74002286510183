.hero.property {
    margin-bottom: 50px;
}

main.property, main.port {
    padding: 25px 0; 
    .panel:last-child { 
        margin-bottom: 0; 
    } 
    h3 {
        padding-top: 140px;
        margin-top: -140px !important;
        pointer-events: none;
    }
}

.property-links {
    margin-bottom: 25px;
    a svg {
        transform: rotate(180deg);
    }
    .col:last-child {
        text-align: right; 
    }

    @include breakpoint(grid-xs) {
        .col.col-6 {
            flex: 0 0 50%!important;
        }
    }
}

.property-menu {
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey; 
    background: #FFF;
    ul {
        display: flex;
        justify-content: space-between;
        @include breakpoint(grid-xs) {
            flex-wrap: wrap;
        }
    }
    li {
        margin: 0;
        @include breakpoint(grid-xs) {
            flex-basis: 100%;
        }
    }
    a {
        display: block;
        padding: 15px 0; 
        @include font-size(16);  
        color: $grey;
        &:hover {
            text-decoration: none; 
            color: $orange;  
        }
    }
    span {
        display: block;
        padding: 15px 0; 
        @include font-size(16);  
        color: #CCC;
    }
    .wrapper-padded {
        background: #FFF;
        & > a {
            display: none;
        }
    }
    &.fixed {
        position: fixed;
        width: 100%;
        left: 0;
        top: 105px; // +40px for covid banner (also see main.js PortMenu: function )
        z-index: 99;
    }
    @include breakpoint(grid-sm) {
        &.fixed {           
            top: 130px;
        }
    }
    @include breakpoint(grid-xs) {
        .wrapper-padded {
            padding: 0;
            & > a {
                position: relative;
                display: block;
                padding: 15px 0;
                background: $abp_blue;
                color: #FFF;
                font-weight: bold;
                text-align: center;
                text-decoration: none !important;
                &:after {
                    position: absolute;
                    display: block;
                    content: "+";
                    top: 15px;
                    right: 15px;
                    color: #FFF;
                }
                &.open:after {
                    content: "-";
                }
            }
        }        
        ul {
            display: none;
        }
        li {
            a {
                text-align: center;
                background: #FFF;
                border-bottom: 1px solid #DDD;
            }
            &:last-of-type a {
                border-bottom: none;
            }
        }
    }
}

.property-header {
    h1 {
        margin: 45px 0 5px 0;
        @include font-size(56);  
        font-weight: bold; 
        color: $orange;
        @include breakpoint(grid-sm) {
            @include font-size(48);  
        }
    }
    h2 {
        margin-bottom: 45px;
        @include font-size(28); 
        color: $grey;
        @include breakpoint(grid-sm) {
            @include font-size(22);  
        }  
    }
    .logo {
        position: relative;
        min-height: 186px;
        padding: 40px 50px 60px;
        background: #F4F4F4;   
        text-align: center;
        .contact, .telephone {
            position: absolute;
            bottom: 0;
            right: 0;   
        }
        .telephone {
            width: 40px;
            height: 40px;
            padding: 7px 10px;
            background: $orange;
            transition: linear .25s background;
            svg {
                max-width: 100%;   
            }
            &:hover {
                background: lighten($orange, 10%);   
            }
        }
        .contact {
            right: 40px;
            display: block;
            padding: 5px 10px;
            background: #FFF;
            @include font-size(12);
            color: $orange;   
        }
        @include breakpoint(grid-sm) {
            padding: 50px;
        }
    }
}

.property-features {
    h3, h4, a {
        margin: 0 0 .5em;
        color: $orange;   
    }
    h3 {
        @include font-size(32);  
        @include breakpoint(grid-sm) {
            @include font-size(28);  
        }
    }
    h4 {
        @include font-size(20);   
        margin-top: 1em;
        &:first-of-type {
            margin-top: 0;
        }
    }
    h5 {
        margin: 1.5em 0 .5em 0;
        @include font-size(18);   
    }
    a {
        margin: 0;   
    }
    p {
        margin-bottom: 1em !important;
    }
    .cog-feature {
        p {
            display: inline;
        }
        &.external-link p {
            display: block; 
            &.small {
                margin: 2em 0 .5em !important;
            }
            a {
                text-decoration: none;                       
                line-height: normal;
                z-index: 90;
            }
        }
    }
    p.small {
        @include font-size(14);   
    }
    .highlight-features p:last-child {
        margin-bottom: 35px;
    }
    ul.plus-bullets {
        margin-bottom: 1em;
        li {
            position: relative;
            padding: 0 0 10px 20px;
            line-height: 130%;
            @include font-size(16);
            &:before {
                content: "+";   
                position: absolute;
                left: 0;
                top: 0;
                font-weight: bold;
                color: $orange;
            }
        }
    }
    .accommodation {
        margin-top: 1em;
        .accommodation-block {
            padding: 10px 15px;
            border-bottom: 1px solid $grey;
            background: darken($pastel_orange, 5%);
            p {
                @include font-size(14);
                margin-bottom: 0 !important;
            } 
            &.total {
               background: #F4F4F4 !important;
                strong {
                    color: $orange;   
                }
            }
        }
        & + .cog-feature {
            margin-top: 25px;
            p {
                @include font-size(14);
            }
        }
    }
    .travel-times {
        margin-bottom: 25px;
        a {
            display: inline-block;
            float: left;
            width: 60px;
            height: 60px;
            padding: 10px;
            margin-right: 5px;
            background: $grey;
            text-align: center; 
            transition: linear .25s background;
            &:hover {
                background: lighten($grey, 10%);
            }
            &.current {
                background: $orange;   
            }
            svg {
                width: 100%;
                height: 100%;
                max-height: 100%;  
                * {
                    stroke: #FFF;   
                }
            }
            @include breakpoint(grid-sm) {
                width: 45px;
                height: 45px;   
            }
        }
        .travel-times-content {
            clear: left;
            padding: 15px 25px;
            background: $pastel_orange;
            table {
                width: 100%;
                margin-bottom: 5px;
                tr {
                    border-bottom: none;
                }   
                th, td {
                    padding: 3px 0;   
                }
                th {
                    color: $orange;   
                }
            }
        }
    }
    .find-out-more {
        display: block;
        padding-top: 15px;
    }
    #property-map {
        height: 462px;
        min-height: 462px;
        height: calc(100% - 25px); 
        margin-bottom: 25px;
        background: $light_grey;   
    }
    .property-downloads {
        a {
            display: block;
            border-bottom: 1px solid $grey;  
            margin-bottom: 10px;
            span {
                position: relative;
                display: inline-block;
                padding: 1px 32px 8px 0;
                @include font-size(18);   
                text-decoration: none;
                color: $grey;
                svg {
                    @include absolute(0, 0);
                    width: 25px;
                    height: 25px; 
                    circle { fill: $grey; }
                }
            }
            &:hover {
                border-bottom: 1px solid $orange;  
                span {
                    color: $orange;
                    svg circle { fill: $orange; }
                }
            }
            @include breakpoint(grid-sm) {
                margin-bottom: 25px;   
            }
        }
    }
    .epc img {
        width: 300px;
        margin-bottom: 25px;
        @include breakpoint(grid-sm) {
            margin-top: 25px;   
        }
    }
    .property-agents {
        .agent {
            img {
                width: 80px;
                margin-bottom: 25px;   
            }
            h4 {
                margin-bottom: 0;   
            }
            p {
                &.contacts {
                    margin-bottom: 25px;   
                    @include font-size(14);  
                    span {
                        position: relative;
                        display: block;
                        padding-left: 15px;
                        &:before {
                            @include absolute(-1px, auto, auto, 0);
                            font-weight: bold;   
                        }
                        &.e:before {
                            content: "e:";   
                        }
                        &.t:before {
                            content: "t:";   
                        }
                    } 
                }                
            }
        }
        .abp-agents {
            padding: 15px;
            background: $pastel_orange;
            border: 1px solid $orange;
            margin-bottom: 25px;
            & > .grid {
                margin-top: 15px;
            }
            .contacts { 
                padding-bottom: 25px;
            }
        }
        .contact-form {
            input, textarea {
                width: 100%;
                margin-top: 25px;
                padding: 8px 15px;
                border: 1px solid $grey;
                font-weight: 300;  
                outline: none; 
            }
            textarea {
                height: 80px;
                resize: none;
            }
            button.ghost {
                margin: 25px 0;
                border-color: $orange;   
                color: $orange;
                &:hover {
                    background: $pastel_orange;   
                }
            }
        }
    }
    img {
        margin-top: 0;   
    }
}

.property-misreps {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid $grey;
    p {
        margin: 0;
        @include font-size(12);   
    }
}

.you-may-also-like {
    padding: 50px 0 20px;
    h2 {
        margin-bottom: 25px;
        @include font-size(28);
        color: $orange;   
    }
    .grid {
        margin-bottom: 0;   
    }
}

.property.land {
    .property-header h1,
    .property-header .logo .contact,
    .property-menu a:hover,
    .property-features h3,
    .property-features h4,
    .property-features a,
    .property-features .property-agents .contact-form button.ghost,
    .property-features ul.plus-bullets li:before {
        color: $green;
    }
    .property-header .logo .telephone {
        background: $green;
    }
    .property-features .property-agents {
        .abp-agents {
            border-color: $green;
            background: $pastel_green;
        }
        .contact-form button.ghost {
            border-color: $green;
            &:hover {
                background: $pastel_green;
            }
        }
    }
    .property-features .accommodation .accommodation-block {
        background: #C8EEDD;
        &.total strong {
            color: $green;
        }
    } 
}
.you-may-also-like.green h2 {
    color: $green;
}
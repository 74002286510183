.hero {
    &.port {
        background: url("/images/hero/port.jpg");
        background-size: cover;
        background-position: center center;
    }
    &.carousel {
        background: none;
        display: block;
        #port-carousel {
            height: 45vw;
            min-height: 537px;
            max-height: 850px;
            .item {
                height: 45vw;
                min-height: 537px;
                max-height: 850px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
            .carousel-control {
                z-index: 500;
            }
        }
    }
}

main.port {
    h1, h2, h3, h4, p a {
        color: $light_blue !important;   
    }    
    .panel.no-bottom-padding {
        padding-bottom: 0;   
    }
    .property-links {
        a:hover {
            color: $light_blue;
            svg circle { fill: $light_blue; }   
        }
    }
    .property-menu {        
        a:hover {
            color: $light_blue;  
        }
    }
    .abp-external-link {
        padding: 20px 25px;
        border: 1px solid $light_blue;
        @include breakpoint(grid-xs) {
            margin-bottom: 25px;    
        }
    }
    
    .port-intro {
        margin-top: 50px;
        padding: 35px 40px;
        background: url('/images/blue_pattern.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: #CCC;
        h2 {
            margin-bottom: 25px;
            font-weight: 700;
            color: #CCC !important;
            @include font-size(40);
        }
        h3 {
            margin-bottom: 5px;
            color: #CCC !important;
            @include font-size(26);
        }
        p {
            margin-bottom: 25px;
            @include font-size(18);
        }
        a {
            display: inline-block;
            padding: 10px 15px;
            background: $green;
            border: 1px solid #FFF;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: bold;
            color: #FFF;
            @include font-size(18);
        }
    }
    
    .property-features {
        .svg-link {
            margin-top: 1em;
        }
        .travel-times {
            a.current {
                background: $light_blue;   
            }
            .travel-times-content {
                background: $pastel_blue;
                & > div {
                    display: none;
                    &:first-child {
                        display: block;   
                    }
                }
                table th {
                    color: $light_blue;  
                }   
            }
        }
    }
    .demographics {
        .demographic {
            margin-bottom: 20px;
            .value {
                border-bottom: 1px solid $grey; 
                letter-spacing: -1px;  
                @include font-size(32);
                font-weight: bold;
                span {
                    @include font-size(18);
                    span {
                        font-weight: 300;
                        @include font-size(12);
                    }
                }
            }
            .key {
                padding-top: 10px;
                @include font-size(12);
            }
        }
    }
    .port-properties {
        .meta {
            @include font-size(16);
            margin-bottom: 1em;   
        }
        p {
            margin-bottom: .5em;
            @include font-size(14);   
        }
        a {
            margin: 0 0 40px !important;   
            &.ghost {
                color: $grey !important;   
            }
        }
        .angled-single-line span {
            max-width: none;
        }
    }
    @include breakpoint(grid-xs) {
        h3[id] {
            padding-top: 215px;
            margin-top: -215px !important;
        }
    }
}

.bottom-help {
    padding: 50px;
    margin-bottom: 25px;
    border: 1px solid $grey;
    background: $pastel_blue;
    h2 {
        @include font-size(38);
        margin-bottom: .5em;
    }
    p.large {
        @include font-size(18);
        margin-bottom: 1em;
    }

    @include breakpoint(grid-xs) {
        .grid div:last-child {
            display: none; // Hide image on mobile
        }
    }
}

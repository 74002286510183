.mimi {
    clear: both;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    a {
        text-decoration: none; 
        color: $mimi_main;
        &:hover {
            color: $mimi_sub;
        }
    }
    .cta-button {
        align-items: center;
        display: flex;
        width: 100%;
        svg {
            width: 40px;
            margin-left: 30px;
            line, polygon {
                fill: $dark_blue;
                stroke-width: 2.5px;
                &:hover {
                    color: $mimi_sub;
                }
            }
            line {
                stroke: $dark_blue;   
            }
            circle {
                display: none;
            }
            @media screen and (max-width: 1030px) {
                margin-left: 15px;
            }
        }
    }
    .mimi-large {
        position: relative;
        height: 510px;
        overflow: hidden;
        background-image: url("/images/mimi-bg.png");
        background-color: $mimi_main;
        background-size: cover;
        background-position: center center;
        .legend {
            @include absolute(30px, auto, auto, 30px);
            overflow: hidden;
            width: 900px;
            padding: 25px 230px 25px 30px;
            background: $mimi_main;
            @media screen and (max-width: 1030px) {
                padding-right: 30px;
                width: 85%;
            }
            h2, h3, h4, p, a {
                font-weight:800;
                position: relative;
                z-index: 1;
                color: #FFF;
            }
            h2 {
                color: $mimi_alt;
                margin-bottom: .5em;   
                @include font-size(36);
            }
            h3 {
                @include font-size(46);
                @media screen and (max-width: 1030px) {
                    @include font-size(26);
                }
            }
        }
        .cta {
            position: relative;
            width: 510px;
            padding: 15px 30px;
            top: 375px;
            left: 30px;
            z-index: 1;
            background: $mimi_alt;
            @include font-size(16);
            color: $mimi_main;
            span {
                font-weight: 500;
                @include font-size(24);   
            }
            &:after {
                @extend %pseudo-right-slant;
            }
        }
        img {
            position: relative;
            width: 460px;
            left: 780px;
            bottom: 68px;
            z-index: 1;
        }
    }
    .mimi-mid {
        position: relative;
        height: 440px;
        overflow: hidden;
        background-image: url("/images/mimi-bg3.png");
        background-color: $mimi_main;
        background-size: cover;
        background-position: center center;
        .legend {
            @include absolute(30px, auto, auto, 30px);
            overflow: hidden;
            width: 1080px;
            height: 380px;
            padding: 25px 40% 25px 30px;
            background: $mimi_main; 
            @media screen and (max-width: 1030px) {
                padding-right: 30px;
                width: 85%;
            }
            h2, h3, h4, p, a {
                font-weight:800;
                position: relative;
                z-index: 1;
                color: #FFF;
            }
            h2 {
                color: #fff !important;
                margin-bottom: .5em;   
                @include font-size(34);
            }
            h3 {
                color: $mimi_alt !important;
                @include font-size(38);
                @media screen and (max-width: 1030px) {
                    @include font-size(20);
                }
            }
        }
        .cta {
            position: relative;
            width: 305px;
            padding: 15px 30px;
            top: 320px;
            left: 60px;
            z-index: 1;
            background: $mimi_alt;
            color: $mimi_main;
            @media screen and (max-width: 1030px) {
                width: 200px;
                padding: 10px 20px;
            }
            span {
                font-weight: 500;
                @include font-size(18);   
            }
            &:after {
                @extend %pseudo-right-slant;
                
            }
        }
        img {
            position: relative;
            height: 380px;
            left: 703px;
            bottom: 52px;
            z-index: 1;
        }
    }
    .mimi-small {
        position: relative;
        height: 360px;
        overflow: hidden;
        background-image: url("/images/mimi-bg2.png");
        background-color: $mimi_main;
        background-size: cover;
        background-position: center center;
        .legend {
            @include absolute(30px, auto, auto, 30px);
            overflow: hidden;
            width: 1080px;
            height: 300px;
            padding: 25px 35% 25px 30px;
            background: $mimi_main; 
            @media screen and (max-width: 1030px) {
                padding-right: 30px;
                width: 85%;
            }
            h2, h3, h4, p, a {
                font-weight:800;
                position: relative;
                z-index: 1;
                color: #FFF;
            }
            h2 {
                color: $mimi_alt !important;
                margin-bottom: .5em;   
                @include font-size(26);
            }
            h3 {
                color: #fff !important;
                @include font-size(36);
                @media screen and (max-width: 1030px) {
                    @include font-size(20);
                }
            }
        }
        .cta {
            position: relative;
            width: 245px;
            padding: 15px 30px;
            top: 235px;
            left: 60px;
            z-index: 1;
            background: $mimi_alt;
            color: $mimi_main;
            @media screen and (max-width: 1030px) {
                width: 200px;
                padding: 10px 20px;
            }
            span {
                font-weight: 500;
                @include font-size(18);   
            }
            &:after {
                @extend %pseudo-right-slant;
                
            }
        }
        img {
            position: relative;
            width: 320px;
            left: 790px;
            bottom: 30px;
            z-index: 1;
        }
    }
}
body {
    padding-top: 65px;
    overflow-x: hidden;
    background: #FFF;
    @include breakpoint(grid-sm) {
        padding-top: 130px;    
    }
}

.site-wrapper {
	@extend %center-block;
	position: relative;
}

.wrapper, .wrapper-padded {
	@extend %center-block;
    width: 100%;
    max-width: $max_width;
}

.wrapper-padded {
    padding: 0 70px;  
    @include breakpoint(grid-sm) {
        padding: 0 50px;   
    }
    @include breakpoint(grid-xs) {
        padding: 0 35px;   
    }
    @include breakpoint(iphone5) {
        padding: 0 25px;   
    }
}

main {
    padding: 70px 0 20px; 
    @include breakpoint(grid-xs) {
        padding: 35px 0 10px;   
    }  
}

/**********************************
/*  HEADER, NAVIGATION and SEARCH
**********************************/
header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    .banner {
        min-height: 65px;
        background: #FFF;

        .info-banner {
            width: 100%;
            background-color:$mimi_main;
            a {
                display: inline-block;
                text-decoration: none;
                padding: 5px 0;
                font-size: 1.5em;
                font-weight: 800;
                color: $mimi_alt;
                &:first-of-type {
                    padding-left: 10px;
                }
                &:hover {
                    i {
                        color: $mimi_alt;
                    } 
                }
                i {
                    text-decoration: underline;
                    font-style: normal;
                }
                span {
                    color:white;
                    font-weight: 400;
                }
                background-color:$mimi_main;
                @include breakpoint(grid-xs) {
                    padding-right:0;
                    @include font-size(16); 
                }
            }
            
        }

        /* Ensures logo stays the same width */
        .col:first-child {
            flex: 0 0 255px;   
        }

        /* Ensures phone number stays the same width */
        .col:nth-child(2) {
            flex: 0 0 160px;
            padding: 0;
            @include breakpoint(alpha) {
                display: none;   
            }
        }

        /* Ensures navigaton bar stays the same width */
        .col:nth-child(3) {
            flex: 1 0 465px;
            padding: 0;
            @include breakpoint(grid-sm) {
                flex: none;
            }
        }

        /* Hide ABP link on mobile */    
        .col:nth-child(4) {
            flex: 0 0 50px;
            @include breakpoint(alpha) {
                display: none;   
            }
        }

        /* Search button fill the rest of the space, but keeps minimum width */
        .col:last-child {
            flex: 0 1 240px; 
            overflow: hidden; 
            @include breakpoint(grid-sm) {
                flex: none; 
                width: 100%;
            } 
        }

        /* ABP Logo */
        .logo {
            display: block;
            height: 65px;
            padding: 18px 0 0 2em;
            img {
                width: 100%;
                max-width: 190px;
                @include breakpoint(grid-sm) {
                    max-width: 150px;
                }
            }
            @include breakpoint(grid-sm) {
                padding-top: 21px;
            }
        }

        /* Telephone number */
        .phone {
            height: 65px;
            padding: 23px 0 0 1em;
            @include font-size(14);
            span {
                @include font-size(18);  
                font-weight: bold;
                color: $abp_red;
                position: relative;
                top: 1px;
            }
        }

        /* Navigation menu */
        @import "nav";

        /* ABP corporate link */
        .abp-corporate {
            position: relative;
            display: block;
            height: 65px;
            padding: 25px 0 0 15px;
            font-weight: 400;
            color: $abp_blue;
            @include font-size(14);
            text-decoration: none;
            &:before {
                @extend %angled-edge;
                top: 20px;
                left: 5px;
                z-index: 5;
                width: 2px;
                height: 25px;
                background-color: $abp_blue;            
            }

        }

        /* "Land & property search" button */
        .search {
            display: block;
            position: relative;
            z-index: 10;
            float: right;
            width: 190px;
            height: 65px;
            @include font-size(14);
            text-decoration: none;
            color: #FFF; 
            &:before {
                @extend %angled-edge;
                width: 50px;
                height: 125px;
                background-color: $abp_red;            
            }
            svg {
                @include absolute(20px, auto, auto, 5px);
                height: 25px;
                @include breakpoint(grid-xs) {
                    @include absolute(20px, auto, auto, 75px);
                }
                @include breakpoint(iphone5) {
                    display: none;
                }
            }
            .search-container {
                height: 65px;
                padding: 24px 0 0 30px;
                background: $abp_red;
                @include breakpoint(grid-sm) {
                    display: inline-block;
                    width: 250px; 
                    padding: 21px 0 0 30px;
                    background-size: 30px 30px;
                    @include font-size(18);
                }
            }
            @include breakpoint(grid-sm) {
                float: none;
                width: auto;
                background: $abp_red;
                text-align: center;
                &:before { display: none; }
            }
        }
    
    }
    
}

/***************
*  HERO IMAGES
***************/
@import "hero";


/***********
*  BYLINES
***********/
.byline {
    min-height: 175px;
    p {
        width: 90%;
        max-width: 900px;
        margin: 0 auto;
        padding: 2em 0;
        text-align: center;
        @include font-size(32); 
        color: $dark_blue; 
        @include breakpoint(grid-xs) {
            padding: 1.5em 0;
            @include font-size(18); 
        }
    }
    @include breakpoint(grid-xs) {
        min-height: 95px;   
    }
    &.red {
        background: $abp_red;
        p { 
            color: #FFF;
        }
    }
    &.purple {
        background: $abp_purple;
        p { 
            color: #FFF;
        }
    }
}

.stripe { 
    background: url("/images/stripe.png") #FFF; 
}

/**************
*  KEY POINTS  
**************/
.key-point {
    margin-bottom: 50px;
    .value {
        display: block;
        height: 115px;
        @include font-size(56);
        font-weight: 700;   
        line-height: 85%;
        letter-spacing: -3px; 
        span {
            @include font-size(56);
            letter-spacing: -3px;
            display: inline-block;
            padding-left: 10px;
            @include breakpoint(grid-sm) {
                @include font-size(36);
            }
        }
        &.number { 
            @include font-size(120); 
            letter-spacing: -7px;
            @include breakpoint(grid-sm) {
                @include font-size(78);
            }
        }
        &.text { 
            font-weight: 800; 
        }
        &.large { 
            @include font-size(96);
            @include breakpoint(grid-sm) {
                @include font-size(54);
            }
        }
        @include breakpoint(grid-sm) {
            @include font-size(36);
            height: 80px;
        }
    }
    .value, .key strong { 
        color: $purple; 
    }
    .key { 
        margin-bottom: 1.5em; 
        min-height: 43px;
        @include breakpoint(grid-xs) {
            min-height: inherit;   
        }
    }
    &.blue {
        .value, .key strong { 
            color: #34508D; 
        }
        a:hover {
            color: #34508D;
            svg circle { fill: #34508D; }   
        }
    }
    &.green {
        .value, .key strong { 
            color: $green; 
        }
        a:hover {
            color: $green;
            svg circle { fill: $green; }   
        }
    }    
    &.light-green {
        .value, .key strong { 
            color: $light_green; 
        }
        a:hover {
            color: $light_green;
            svg circle { fill: $light_green; }   
        }
    }
    @include breakpoint(grid-xs) {
        text-align: center;   
        .value {
            &.large { 
                @include font-size(80); 
            }
            
        }
    }
}


/********************
*  HIGHLIGHT PANELS
********************/
@import "layout/panel";


/*******************
*  INTERACTIVE MAP
********************/
@import "layout/map";


/****************
*  CASE STUDIES
****************/
@import "layout/case_studies";


.angled-single-line {
    display: inline-block;
    overflow: hidden;
    @include font-size(12);
    font-weight: 400;
    color: #FFF;
    span {
        position: relative;
        display: inline-block;
        // float: left;
        padding: 5px 21px 5px 15px;
        max-width: 176px;
        &:last-child {
            z-index: 0;
            padding: 5px 15px 5px 3px;
            &:before {
                @extend %angled-edge;
                top: -20px;
                left: -20px;
                width: 50px;
                height: 125px;
                background-color: inherit;
            } 
        }
        &.white {
            background: #FFF;
            color: $grey;
        }
        &.grey {
            background: $light_grey;
            color: $grey;
        }
        &.pastel-grey {
            background: $pastel_grey; 
            color: $grey;  
        }
        &.pastel-blue {
            background: $pastel_blue; 
            color: $grey;  
        }
    }
    &.green {
        background: $green;   
    }
    &.orange {
        background: $orange;   
    }
    
}

.ghost {
    display: inline-block;
    padding: 10px 50px;
    background: transparent;
    border: 1px solid #FFF;
    color: #FFF; 
    transition: linear .25s background;
    &:hover {
        text-decoration: none;   
    }
    &.blue {
        border: 1px solid $grey;
        color: $grey;   
        &:hover {
            background: darken($pastel_blue, 7%);   
        }
    }
}

.svg-link {
    position: relative;
    display: inline-block;
    height: 25px;
    padding: 5px 0 0 30px;
    @include font-size(12);   
    text-decoration: none;
    color: $grey !important;
    svg {
        @include absolute(0, auto, auto, 0);
        width: 25px;
        height: 25px; 
        circle { 
            fill: $grey;
        }
    }
    &:hover {
        text-decoration: none;
    }
    &.purple:hover {
        color: $purple !important;
        svg circle { fill: $purple; }   
    }
    &.orange:hover {
        color: $orange !important;
        svg circle { fill: $orange; }   
    }
    &.green:hover {
        color: $green !important;
        svg circle { fill: $green; }   
    }
    &.light-green:hover {
        color: $light_green !important;
        svg circle { fill: $light_green; }   
    }
    &.blue:hover {
        color: $light_blue !important;
        svg circle { fill: $light_blue; }   
    }
    &.white {
        color: #FFF !important;
        svg circle { 
            fill: #FFF;
        } 
    }
}

table {
    width: 100%;
    margin-bottom: 25px;
    th {
        @include font-size(12);   
    }
    tbody tr {
        border-bottom: 1px solid $grey;  
        td {
            padding: 20px 0 8px; 
            &.key-value {
                @include font-size(14);
                span {
                    @include font-size(18);
                    font-weight: 700;
                }  
            }
        }
    }
}

footer {
    padding: 50px 0;
    background: $dark_grey;
    @include font-size(11);   
    color: $light_grey; 
    .links .col {
        border-right: 1px solid #FFF;
        &:nth-child(4n+4) {
            border-right: none;
        }
    }
    a {
        text-decoration: none;
        &, &:link, &:active, &:visited, &:hover {
            color: #FFF;
        }
    }
    h3 {
        margin-bottom: .75em;
        @include font-size(14);
        font-weight: 700;
    }
    ul, p {
        margin-bottom: 40px;   
    }
    .larger p {
        @include font-size(13); 
        padding-right: 35px;
    }
    .lower {
        padding-top: 40px;
        .col {
            border-right: none;
        }
        ul {
            margin-bottom: 0;
            li {
                display: inline-block;   
                margin-right: 25px;               
                &:last-of-type {
                    margin-right: 0;
                }
            }
            &.right {
                float: right;
                li {
                    text-align: right;
                }
            }
        }
    }
    @include breakpoint(grid-xs) {
        text-align: center;
        .links .col {
            border-right: none;
        }
        h3 {
            margin: 1.5em 0 .25em;
        }
        ul, p {
            margin-bottom: 0;
            padding-right: 0 !important;
        }
    }
}

.fixed-download-brochure-link {
    position: fixed;
    z-index: 500;
    right: 0;
    top: 50%;    
    display: block;
    height: 0;
    width: 250px;
    border-bottom: 50px solid $abp_red;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;        
    text-align: center;
    text-decoration: none !important;
    transition: all .25s ease;
    transform: rotate(-90deg) translateX(175px);
    transform-origin: bottom right;
    &:hover {
        border-bottom-color: lighten($abp_red, 10%);
    }
    span {
        display: block;
        padding: 14px 0 0;
        color: #FFF;
        font-weight: bold;
        @include font-size(18);
    }
}

.fixed-download-brochure {
    position: fixed;
    z-index: 550;
    right: -480px;
    top: 50%;  
    width: 430px;
    transition: right .5s ease;
    transform: translateY(-50%);
    .download-brochure {
        position: relative;
        height: auto;        
        padding: 50px;
        min-height: inherit;
        max-height: none;
        margin: 0;
        h2 {
            @include font-size(32);
            color: $mimi_main;
        }
        p {
            padding-right: 0;
            margin-bottom: 25px;
            color: $mimi_main;
        }
        .button {
            text-align: center;
            width: 100%;
            margin: 0 0 25px;
            background-color: $mimi_main;
            color: #FFF;
            &:last-of-type {
                margin: 0;
            }
        }
        .close {
            position: absolute;
            top: 25px;
            right: 25px;
            opacity: 1;
        }
    }
}

.back-to-top {
    display: none;
    position: fixed;
    z-index: 550;
    bottom: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    background: #FFF;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

a.anchor {
    display: block;
    padding-top: 65px;
    margin-top: -65px;
    &.sub {
        padding-top: 126px;
        margin-top: -126px;
    }
}

#cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    background: rgba(0, 0, 0, .8);
    padding: 10px 15px;
    p {
        text-align: center;
        color: #FFF;
    }
    a {
        display: inline-block;
        background: $abp-blue;
        color: #FFF;
        margin-left: 5px;
        padding: 5px 10px;
        text-decoration: none !important;
    }
}

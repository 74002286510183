main.what-we-do {
    .grid {
        margin: 0 -2em;
        .col {
            padding: 0 2em;
        }
        &.padded-top {
            padding-top: 30px;
        }
        .padded-bottom {
            margin-bottom: 30px;
        }
    }
    p:last-of-type {
        margin-bottom: 1em;
    }    
    .key-point {
        .value {
            line-height: 120%;
        }
        .key {
            max-width: 225px;
        }
    }
}

.hero.what-we-do {
    background-image: url("/images/hero/what_we_do.jpg");   
}


.first-block {
    .narrow-content {
        max-width: 380px;
        padding-left: 35px;
    }
    .padded-content {
        padding: 35px 50px 0 0;
    }
    h2 {
        margin: 0 0 30px;
    }
    .small-pic {
        max-width: 400px;
    }
    @include breakpoint(grid-sm) {
        .narrow-content, .padded-content {
            max-width: none;
            padding: 0 0 50px 0;
        }   
        h2 {
            margin: 0 0 25px 0;
        }
        .second-pic {
            margin-bottom: 25px;
        }
    }
}

.second-block {
    h2 {
        max-width: 380px;
        margin-bottom: 50px;
        color: #34508D;
    }
    .small-pic {
        max-width: 300px;
        margin-top: 0;
    }    
    .svg-link {
        margin-top: 30px;
    }
    @include breakpoint(grid-sm) {
        h2 {
            max-width: none;
        }    
        .small-pic {
            margin: 25px 0 !important;
        }
        .padded-content {
            padding: 0;
        }
    }
}

.third-block {
    h2 {
        margin-bottom: 30px;
        max-width: 300px;
    }
    p {
        margin-bottom: 30px;
    }
    .narrow-content {
        max-width: 410px;
    }
    @include breakpoint(grid-sm) {
        h2, .narrow-content {
            max-width: none;
        }
        h2, p, .svg-link {
            margin-bottom: 25px;
        }
    }
}
    
.fourth-block {
    .col:first-of-type > img {
        margin-bottom: 25px;
    }
    p.small {
        font-style: italic;
        @include font-size(12);   
    }
    .square-image {
        margin: 50px 0 0 0 !important;
        &:first-of-type {
            float: right;
        }
        &:nth-of-type(2) {
            clear: both;
            float: left;
        }
    }
    @include breakpoint(grid-sm) {
        .col:first-of-type {
            order: 2;
        }
        .col:nth-of-type(2) {
            order: 1;
        }
        .square-image {
            margin: 50px 25px 25px 0 !important;
            clear: none !important;
            float: left !important;                
        }
    }
    @include breakpoint(grid-xs) {
        margin-bottom: 0;    
        padding-bottom: 0;
    }
}

@include breakpoint(grid-xs) {
    .download-brochure-wrapper {
        padding-left: 0;
        padding-right: 0;
        .half-panel.download-brochure {
            max-height: none;
            margin-bottom: 0;
            button {
                margin-bottom: 25px;
            }
        }
    }
}

.centered {
    text-align: center;
}

.hero.supplementary {
    height: 300px;
    background: $abp_blue;
    &:before {
        display: none;
    }
    h1 {
        color: #FFF;
    }
}

.paragraph-text {
    padding-bottom: 50px;
    h2 {
        margin: 1.5em 0 .5em;
        color: $abp_blue;
        @include font-size(28);
    }
    p, li {
        @include font-size(16);
    }
    ul {
        margin: 0 0 1.5em 2.5em;
    }
    li {
        list-style-type: disc;
        margin-bottom: 8px;
    }
}
.hero.search {
    display: block;
    height: auto;
    padding: 35px 0;
    background: $abp_red;
    min-height: inherit;
    color: #FFF;
    &:before {
        display: none;   
    }
    h1 {
        text-align: left;
        @include font-size(38);
    }
    form {
        padding-top: 35px;
        .dropdown {
            position: relative;
            padding: 10px 15px 10px 45px;
            margin-bottom: 15px;
            border: 1px solid $grey;
            background: url("/images/icon/right_arrow_blue.png") 15px center no-repeat #FFF;  
            background-size: 18px 9px;
            color: $grey;
            cursor: pointer; 
            .options {
                display: none;
                @include absolute(40px, auto, auto, 0);
                z-index: 35;
                width: 100%;
                max-height: 280px;
                overflow: auto;
                border: 1px solid $grey;
                background: #FFF;                  
            }
            .opt {
                @include font-size(12);
            }
            label {
                display: block;
                padding: 5px 15px;
                margin: 0;
                font-weight: 400; 
                cursor: pointer;  
            }
        }
        .hidden-radio {
            label {
                margin: 0;
                font-weight: 400;
                cursor: pointer;   
            }
            input[type=radio] {
                display: none;   
                &:checked + label {
                    font-weight: 700; 
                    text-decoration: underline;
                }
            }
        }
        button.ghost {
            &:hover {
                background: lighten($abp_red, 7%);
            }
        }
        .selected-options {
            margin-top: 5px;
            .tag {
                position: relative;
                display: inline-block;
                padding: 5px 25px 5px 8px;
                margin: 10px 10px 0 0;
                background: lighten($abp_red, 7%);
                @include font-size(12);
                color: #FFF;
                transition: linear .25s background;
                span {
                    @include absolute(12px, 8px);
                    display: block;
                    width: 9px;
                    height: 1px;
                    background: #FFF;
                    transform: rotate(45deg);
                    &:after {
                        content: " ";
                        position: absolute;
                        top: -4px;
                        left: 4px;
                        display: block;
                        width: 1px;
                        height: 9px;
                        background: #FFF;
                    }
                }
                &:hover {
                    background: lighten($abp_red, 15%);
                    text-decoration: none;   
                }
            }
        }
        @include breakpoint(grid-xs) {
            .grid {
                margin: 0 -7px;
                .col {
                    padding: 0 7px;
                }
            }
            .hidden-radio {
                display: inline-block;
                border: 1px solid #FFF;
                padding: 10px 5px 0;
                margin-right: 5px;
                width: calc(50% - 5px);
                height: 42px;
                float: left;
                text-align: center;
                &:last-of-type {
                    margin-right: 0;
                    width: 50%;
                }
            }
            .search-button {
                order: 8;
                .ghost {
                    margin-top: 15px;
                    width: 100%;
                }
            }
        }
    }
}

#search-results {
    padding-top: 65px;
    margin-top: -65px;
    min-height: 240px;
    @include breakpoint(grid-xs) {
        text-align: center;
    }
}

.result-count {
    @include font-size(24);
    display: block;
    margin-top: 28px;
}

.results-views {
    padding-top: 25px;
    text-align: right;    
    a {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-left: 15px;
        border-radius: 50%; 
        background: $grey;
        span {
            position: absolute;
            display: block;    
            background: #FFF;
            &:before, &:after {
                content: "";
                display: block;
                position: absolute;
                background :#FFF;   
            }
        } 
        &.current {
            background: $abp_red;   
        }
        &.list-view {
            span {
                top: 19px;
                left: 8px;
                width: 24px;
                height: 2px;
                &:before {
                    width: 24px;
                    height: 2px;
                    top: -6px;
                }
                &:after {
                    width: 24px;
                    height: 2px;
                    top: 6px;
                }
            }
        }
        &.card-view {
            span {
                top: 10px;
                left: 21px;
                width: 9px;
                height: 9px;
                &:before {
                    top: 0px;
                    left: -11px;
                    width: 9px;
                    height: 9px;    
                }
                &:last-child {
                    top: 21px;
                }
            }
        }
    }
    @include breakpoint(grid-xs) {
        text-align: center;
        a {
            margin: 0 10px;
        }
    }
}

.map-wrapper {
    position: relative;
    top: -85px;
    margin-bottom: -115px;
    #results-map {
        height: 500px;
    }
    @include breakpoint(grid-xs) {
        top: 0;
        margin-bottom: 0;
    }
}

.results {
    margin-bottom: 50px;
    .result {
        display: block;
        position: relative;
        height: 20vw; 
        max-height: 270px;
        margin-bottom: 30px;
        background-size: cover;  
        .legend {
            @extend %legend;
            top: 15px;      
            left: 15px;
            width: 295px;   
            min-height: 120px;
            padding: 15px 80px 15px 20px;
            &:after {
                background: rgba($abp_blue, .9);   
            }
            h3 {
                margin-bottom: 5px;
                @include font-size(26);
            }
            h4 {
                margin-bottom: 10px;
                @include font-size(14);
            }
            p {
                @include font-size(12);
                margin-bottom: 0;
            }
        }
        .meta {
            @include absolute(auto, 15px, 15px);   
        }
        @include breakpoint(grid-sm) {
            height: 30vw;   
        }
        @include breakpoint(grid-xs) {
            height: 60vw;   
            .legend {
                width: calc(100% - 30px);
                padding: 15px 20px;
                background: rgba($abp_blue, .9);
                &:after {
                    display: none;
                }
            }
        }
        @include breakpoint(iphone5) {
            height: 70vw;
            .legend {
                padding: 15px 20px;
                right: 15px;
                width: calc(100% - 30px);
                background: rgba(9,44,116,0.9);
                &:after {
                    display: none;
                }
                h3 {
                    @include font-size(20);
                    margin-bottom: 12px;
                }
            }
        }
    }
    .more-results {
        display: flex;
        align-items: center;
        height: 20vw; 
        background: $light_grey; 
        span {
            display: block;
            width: 100%;
            text-align: center;
            @include font-size(64);
            font-weight: bold;  
            color: darken($light_grey, 15%);
        }
        &:hover {
            text-decoration: none;   
        }
    }
}

.list-view {
    display: none;
    table {
        td {
            padding: 20px 0;
        }
        .available-to-let, .build-to-suit {
            display: inline-block;
            width: 125px;
            padding: 5px 16px 5px 15px;
            @include font-size(14);
            font-weight: 400;
            color: #FFF;
            text-align: center;
        }
        .available-to-let {
            background: $orange;   
        }
        .build-to-suit {
            background: $green;  
        }
    }
    @include breakpoint(grid-xs) {
        thead {
            display: none;
        }
        table, thead, tbody, tr, td {
            display: block;
        }    
        tr td {
            padding: 0 0 20px;
            &:first-of-type {
                padding-top: 20px;
            }
        }        
    }
}
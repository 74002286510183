.hero.our-ports {
    background-image: url("/images/hero/our_ports.jpg");   
}

.our-ports-header {
    h2 {
         margin-bottom: 1em;
         @include font-size(32);
         color: $grey;
         @include breakpoint(grid-xs) {
            text-align: center;
        }
    }
    .results-views {
        padding-top: 0;
        .current {
            background: $light_blue;   
        }
        @include breakpoint(grid-xs) {
            margin-bottom: 25px; 
            text-align: center;
            a {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}

.results .result .legend.white {
    h2, h3, h4, p, a {
        color: $grey;
    }
    &:after {
        background: rgba(#FFF, .9);
    }
    @include breakpoint(grid-xs) {
        width: calc(100% - 30px);
        padding: 15px 20px;
        background: rgba(#FFF, .9);
        &:after {
            display: none;
        }
    }
}

.list-view {
    table.our-ports {
        td {
            @include font-size(18);
        }
        .port-name {
            font-weight: 400;
            color: $light_blue;
        }
        a:hover {
            color: $light_blue;
            svg circle { fill: $light_blue; }   
        }
        @include breakpoint(grid-xs) {
            text-align: center;
        }
    }
}

#our-ports-map {
    height: 700px;
    margin-bottom: 30px;
    @include breakpoint(grid-xs) {
        height: 400px; 
    }
}

#rail-connected {
    color: #FFF;
    a {
        text-decoration: none !important;
        color: #FFF !important;
    }
    .rail-connected__content {
        background: $abp_red;
        padding: 20px 25px;
        
        h2 {
            margin-bottom: 10px;
            @include font-size(28);
            span {
                font-weight: bold;
                @include font-size(42);
            }
        }
        p {
            @include font-size(16);
            line-height: 120%;
        }
    }
    @include breakpoint(grid-xs) {
        margin-left: -35px;
        margin-right: -35px;
    }
}

#make-it-move-it-sidebar {
    margin-top: 50px;
    background-image: url("/images/mimi-bg-small.png");
    background-color: $mimi_main;
    a {
        text-decoration: none;
    }
    h2 {
        color: $mimi_alt;
        font-weight: 800;
        padding: 15px 25px 5px;
        @include font-size(36);
    }
    img {
        padding: 0 25px;
    }
    h3 {
        color: $mimi_alt;
        margin-bottom: 1em;
        font-weight: 600;
        @include font-size(26);
    }
    .mimi-content {
        background: $mimi_main;
        padding: 20px 25px;
        
        h2 {
            margin-bottom: 10px;
            @include font-size(28);
            span {
                font-weight: bold;
                @include font-size(42);
            }
        }
        .cta {
            position: relative;
            width: 210px;
            padding: 5px 20px;
            top: 0;
            left: 0;
            z-index: 1;
            background: $mimi_alt;
            @include font-size(16);
            color: $mimi_main;
            span {
                width: 185px;
                font-weight: 500;
                @include font-size(24);   
            }
            &:after {
                @extend %pseudo-right-slant;
            }
            .cta-button {
                align-items: center;
                display: flex;
                svg {
                    width: 50px;
                    line, polygon {
                        fill: $mimi_main;
                        stroke-width: 2.5px;
                        &:hover {
                            color: $mimi_sub;
                        }
                    }
                    line {
                        stroke: $mimi_main;   
                    }
                    circle {
                        display: none;
                    }
                }
            }
        }
    }
}